import { template as template_d1e6899540d3485e9df49d2ae8c043e7 } from "@ember/template-compiler";
const SidebarSectionMessage = template_d1e6899540d3485e9df49d2ae8c043e7(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
