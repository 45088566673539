import { template as template_148d7f7dafda413e98e0c7a218da3586 } from "@ember/template-compiler";
const FKControlMenuContainer = template_148d7f7dafda413e98e0c7a218da3586(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
