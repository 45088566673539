import { template as template_18e037b0c2b34453948d8c55ed3aaeb5 } from "@ember/template-compiler";
const FKLabel = template_18e037b0c2b34453948d8c55ed3aaeb5(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
