import { template as template_cdaafa10e98849cc9637c4400f63468a } from "@ember/template-compiler";
const FKText = template_cdaafa10e98849cc9637c4400f63468a(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
