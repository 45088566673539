import { template as template_802e653813fe47eba4f013ce6c1bc2cf } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_802e653813fe47eba4f013ce6c1bc2cf(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
